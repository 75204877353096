import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: '952fa5ff-af14-4577-8f28-41e0f714c461',
    development: '9f089aae-22c6-4723-82a1-56dd6060cfd1',
  },
}

const theme = 'synopsis'

export default {
  name: 'Synopsis grammatikk',
  slug: 'synopsis-grammatikk',
  theme: theme,
  namespace: getDataByEnv('namespace', customDataByEnv),
  assets: {
    logo: `/themes/${theme}/images/logo.svg`,
    favicon: `/themes/${theme}/favicon.ico`,
  },
  dbok: {
    nb: 'https://reader.dbok.no/#/',
  },
  styles: [
    `/themes/${theme}/css/${theme}.css`,
    `/themes/${theme}/icons.css`,
    '/common/icons/style.css',
  ],
  welcomeMessage: {
    nb:
      '<h1 class="c-heading">Velkommen til Synopsis</h1><p>Bli kjent med mulighetene i språket! Synopsis Grammatikk er grammatikkundervisning på en helt ny måte. Ta med deg den forlatte roboten Sven til ulike steder og gjør ham til en trygg språkbruker.</p><p>Synopsis Grammatikk er en del av Fagbokforlagets norskverk for ungdomstrinnet.</p>',
  },
  langs: [
    {
      code: 'nb',
      slug: 'synopsis-grammatikk',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      slug: 'synopsis-grammatikk',
      label: 'Nynorsk',
    },
  ],
  hiddenCover: {
    url:
      'https://res.cloudinary.com/edtech/image/upload/w_200,c_scale/v1628077274/coss_dev/9f089aae-22c6-4723-82a1-56dd6060cfd1/3bab4642d1b2ef2d0a399c2fee8b12a4572a752f82423a471d77a9f3831dee17.gif',
  },
}
